<template>
  <Spinner v-if="isLoadingConsignmentList"/>

  <section v-else>
    <h2 v-if="!consignmentList.length">
      Партий нет
    </h2>

    <div v-else>
      <h2 class="mb-30">Партии</h2>

      <div class="consignment-card-list">
        <ConsignmentCard
          v-for="consignment in consignmentList"
          :key="consignment.id"
          :consignment="consignment"
          @goStageNext="consignmentGoStageNextHandler"
          @goSplitPrint="consignmentGoSplitPrintHandler"
          @remove="consignmentRemoveHandler"
          @update="consignmentUpdateHandler"
          @addDirtyWeight="consignmentAddDirtyWeightHandler"
          @addExecutor="consignmentAddExecutorHandler"
        />
      </div>

      <AppPagination
        v-if="pagination.pageCount > 1"
        :current-page="pagination.page"
        :page-count="pagination.pageCount"
        @setPage="setCurrentRouteQueryPage"
      />
    </div>
  </section>
</template>

<script>
import AppPagination from '@/components/ui/AppPagination'
import ConsignmentCard from '@/components/production/consignment/ConsignmentCard'

import { mixinRegisterListPage } from '@/mixins/views/Production/Order/mixinRegisterListPage'
import { mixinModalHandler } from '@/mixins/modal/MixinModalHandler'

import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  components: {
    AppPagination,
    ConsignmentCard
  },

  mixins: [
    mixinRegisterListPage,
    mixinModalHandler
  ],

  computed: {
    ...mapState('consignment', {
      consignmentList: 'consignmentList',
      isLoadingConsignmentList: 'isLoadingConsignmentList',
      pagination: 'pagination'
    })
  },

  methods: {
    ...mapMutations('consignment', {
      REMOVE_CONSIGNMENT: 'REMOVE_CONSIGNMENT',
      UPDATE_CONSIGNMENT: 'UPDATE_CONSIGNMENT',
      CLEAR_STATE: 'CLEAR_STATE'
    }),
    ...mapMutations('warehouse', ['COUNT_CALC_BY_ARTICLE']),
    ...mapActions('consignment', {
      fetchConsignmentListData: 'fetchConsignmentListData',
      loadPage: 'loadPage',
      updateConsignment: 'updateConsignment'
    }),

    async consignmentAddDirtyWeightHandler (data) {
      this.UPDATE_CONSIGNMENT(data)
    },

    async consignmentGoStageNextHandler (data) {
      this.UPDATE_CONSIGNMENT(data)
      await this.fetchConsignmentListData()
    },

    async consignmentGoSplitPrintHandler (data) {
      this.UPDATE_CONSIGNMENT(data)
      await this.fetchConsignmentListData()
    },

    async consignmentAddExecutorHandler (data) {
      this.UPDATE_CONSIGNMENT(data)
    },

    async consignmentRemoveHandler (consignment) {
      this.REMOVE_CONSIGNMENT(consignment.id)
      this.COUNT_CALC_BY_ARTICLE(consignment)
    },

    async consignmentUpdateHandler (data, handlers) {
      const callback = async () => {
        await this.updateConsignment(data)
      }

      await this.modalHandler(handlers, callback)
    }
  }
}
</script>
